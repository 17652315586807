import axiosInstance from '@/service/axios'
import apis from "@/service/apis";
/**
 *
 * @param url 目标下载接口
 * @param query 查询参数
 * @param fileName 文件名称
 * @returns {*}
 */
export function downBlobFile(url, query, fileName, method = 'get') {
  let params = {
    url: apis[url],
    method,
    responseType: 'blob'
  }
  params[method === 'get' ? 'params' : 'data'] = query
  return axiosInstance(params).then((response) => {
    // 处理返回的文件流
    const blob = response.data
    if (blob && blob.size === 0) {
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function () {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  })
}

/**
 * 全局防抖函数
 * @param {Function} func - 要防抖的函数
 * @param {Number} delay - 延迟时间（毫秒）
 * @returns {Funtion} - 防抖处理后的函数
 */
export const debounce = (func, delay) => {
  let timeout
  return function (...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func.apply(context, args)
    }, delay)
  }
}

/**
 * 全局节流函数
 * @param {Function} func - 要节流的函数
 * @param {Number} delay - 间隔时间（毫秒）
 * @returns {Funtion} - 节流处理后的函数
 */
export const throttle = (func, delay) => {
  let timer = null
  let lastRunTime = 0

  return function (...args) {
    const context = this
    const currentTime = Date.now()
    const remainingTime = delay - (currentTime - lastRunTime)

    clearTimeout(timer)

    if (remainingTime <= 0) {
      func.apply(context, args)
      lastRunTime = currentTime
    } else {
      timer = setTimeout(() => {
        func.apply(context, args)
        lastRunTime = Date.now()
      }, remainingTime)
    }
  }
}

/**
 * 数字千分逗号分隔保留两位小数的方法
 * @param {Number} num - 要格式化的数字
 * @returns {String} - 格式化后的字符串
 */
export const formatNumber = (num, decimalDigits = 2) => {
  num += ''
  return parseFloat(num.replace(/[$,]/g, '')).toLocaleString('en-US', { minimumFractionDigits: decimalDigits, maximumFractionDigits: decimalDigits })
}

// 给小于十的月日时分秒前面补零
export const padZero = (time) => {
  const originalDate = new Date(time)
  const year = originalDate.getFullYear()
  const month = String(originalDate.getMonth() + 1).padStart(2, '0')
  const day = String(originalDate.getDate()).padStart(2, '0')
  const hours = String(originalDate.getHours()).padStart(2, '0')
  const minutes = String(originalDate.getMinutes()).padStart(2, '0')
  const seconds = String(originalDate.getSeconds()).padStart(2, '0')
  // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return {
    year,
    month,
    day,
    hours,
    minutes,
    seconds
  }
}

// 根据子级id递归查询所有父级id
export const findPatentValue = (array, targetId, valueKey, childrenKey) => {
  if (!targetId || !Array.isArray(array)) return []
  const result = []
  let valid = false
  const seek = (_array, _targetId) => {
    let parentValue = ''
    const up = (_array_, _targetId_, lastValue) => {
      _array_.forEach((v) => {
        const val = v[valueKey]
        const child = v[childrenKey]
        if (val === _targetId_) {
          valid = true
          parentValue = lastValue
          return
        }
        child?.length && up(child, _targetId_, val)
      })
    }
    up(_array, _targetId)
    if (parentValue) {
      result.unshift(parentValue)
      seek(_array, parentValue)
    }
  }
  seek(array, targetId)
  return valid ? [...result, targetId] : []
}

// 获取当前格式化时间
export const printDate = () => {
  const d = new Date()
  const year = d.getFullYear()
  const month = d.getMonth() > 8 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)
  const date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate()
  const hours = d.getHours() > 9 ? d.getHours() : '0' + d.getHours()
  const minutes = d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes()
  const seconds = d.getSeconds() > 9 ? d.getSeconds() : '0' + d.getSeconds()
  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`
}

/**
 * @description: 年月日时分秒毫秒
 * @return {*}
 */
export function formatTimestamp() {
  // 年月日时分秒毫秒
  const date = new Date();
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const milliseconds = date.getMilliseconds().toString().padStart(3, "0");
  return year + month + day + hours + minutes + seconds + milliseconds;
}

// 批量下载方法
export const batchDownloadFile = (response) => {
  // 假设 response 是 Axios 的响应对象
  const blob = response.data; // Blob 数据
  const url = window.URL.createObjectURL(blob); // 创建 Blob URL
  const contentDisposition = response.headers['content-disposition'];
  let filename = 'downloaded-file.xlsx'; // 默认文件名

  // 尝试从 Content-Disposition 中解析文件名
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename\*?=['"]?(?:utf-8'')?([^;'"\n]*)['"]?;?/i);
    if (filenameMatch.length > 1) {
      // 解码可能存在的 URI 编码
      filename = decodeURIComponent(filenameMatch[1]);
    }
  }

  // 创建一个链接并触发下载
  const link = document.createElement('a');
  link.href = url;
  link.download = filename; // 设置下载的文件名
  document.body.appendChild(link);
  link.click(); // 模拟点击以下载文件
  document.body.removeChild(link); // 下载后移除元素
  window.URL.revokeObjectURL(url); // 释放 URL 对象
}


// 通过url下载文件到本地
export const DownloadUrlFile = (url, filenamePrefix) => {
  // 使用fetch API从URL获取数据
  fetch(url)
    .then(response => response.blob())  // 将响应转换为Blob对象
    .then(blob => {
      // 创建一个指向Blob的URL
      const blobUrl = URL.createObjectURL(blob);
      // 创建一个a元素
      const link = document.createElement('a');
      link.href = blobUrl; // 设置href为Blob的URL
      // 提取文件类型
      const extension = url.split('.').pop();
      // 设置完整的文件名
      const filename = `${filenamePrefix}.${extension}`;
      link.download = filename; // 设置下载属性为构建的文件名

      // 模拟点击该链接
      document.body.appendChild(link);
      link.click();

      // 清理：移除链接和释放Blob URL
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    })
    .catch(error => console.error('Error downloading the file:', error));
}


// 传入标准时间，返回年月日，传日年月日时，返回标准时间
export const convertDateFormat = (dateString) => {
  // 判断输入的日期格式是标准时间还是年月日格式
  if (/^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT\+\d{4} \(.+\)$/.test(dateString)) {
    // 将标准时间格式转换为年月日格式
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    // 将年月日格式转换为标准时间格式
    const parts = dateString.split('-');
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);
    const date = new Date(year, month, day);
    return date.toString();
  } else {
    // 如果输入的日期格式不符合要求，则返回空字符串或者其他指示错误的值
    return "Invalid date format：时间格式不正确";
  }
}


export function timestampToDateTime(timestamp, format='YYYY-MM-DD hh:mm:ss', afterContent = '') {
  if(!timestamp) return undefined
  const date = new Date(timestamp); // 转换为Date对象
  const year = date.getFullYear(); // 获取年份
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 获取月份，转为两位数
  const day = ("0" + date.getDate()).slice(-2); // 获取日，转为两位数
  const hours = ("0" + date.getHours()).slice(-2); // 获取小时，转为两位数
  const minutes = ("0" + date.getMinutes()).slice(-2); // 获取分钟，转为两位数
  const seconds = ("0" + date.getSeconds()).slice(-2); // 获取秒，转为两位数

  return format.replace('YYYY', year).replace('MM', month).replace('DD', day).replace('hh', hours).replace('mm', minutes).replace('ss', seconds) + afterContent
}

export let VueformDefaultTime = ['00:00:00', '23:59:59']




