import Vue from 'vue'
import VueRouter from 'vue-router'
// 白名单（这里是根据路由名字判断的）
const whiteList = ['LoginView', 'register', 'forgetPwd', 'loginIndex']
// 认证状态

Vue.use(VueRouter)
// 全局导航守卫函数 ---逻辑暂时未处理,直接放行，等权限确定再处理
const globalGuard = (to, from, next) => {
  const token = localStorage.getItem('_access_token')
  if (!token && !whiteList.includes(to.name)) {
    next('/loginView')
  } else {
    next()
  }
}

const routes = [
  {
    // 404页面
    path: '*',
    name: '404',
    component: () => import('@/views/ErrorPage/index.vue')
  },
  {
    // 默认登录页
    path: '',
    redirect: 'loginView'
  },
  {
    path: '/MessageMain',
    name: 'MessageMain',
    component: () => import('@/views/MessageCenter/MessageMain')
  },
  // 工作台
  {
    path: '/global',
    name: 'GlobalView',
    redirect: '/globalIndex',
    component: () => import(/* webpackChunkName: "GlobalView" */ '@/views/GlobalView'),
    children: [
      {
        path: '/Staging',
        name: 'Staging',
        activeKey: '/Staging',
        meta: {
          id: 'Staging',
          title: '工作台',
          icon: 'staging'
        },
        component: () => import(/* webpackChunkName: "Staging" */ '@/views/Staging'),
      },
      // 个人设置
      {
        path: '/personalSetting',
        name: 'personalSetting',
        meta: {
          id: 'personalSetting',
          title: '个人设置',
          icon: 'personalCenter'
        },
        component: () => import(/* webpackChunkName: "personalSetting" */ '@/views/personalSetting')
      },
      // 数据可视化
      {
        path: '/globalIndex',
        name: 'Index',
        meta: {
          id: 'Index',
          title: '数据可视化'
        },
        redirect: '/globalIndex/dataProfile',
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Index'),
        children: [
          {
            path: 'dataProfile',
            name: 'DataProfile',
            activeKey: '/globalIndex',
            meta: {
              title: '数据概况'
            },
            component: () => import(/* webpackChunkName: "DataProfile" */ '@/views/Index/DataProfile')
          },
          {
            path: 'productInsight',
            name: 'ProductInsight',
            activeKey: '/globalIndex',
            meta: {
              title: '商品洞察'
            },
            component: () => import(/* webpackChunkName: "ProductInsight" */ '@/views/Index/ProductInsight')
          },
          {
            path: 'ShopInsight',
            name: 'ShopInsight',
            activeKey: '/ShopInsight',
            meta: {
              title: '店铺洞察'
            },
            component: () => import(/* webpackChunkName: "ShopInsight" */ '@/views/Index/ShopInsight')
          }
        ]
      },
      // 订单中心
      {
        path: '/OrderCenter',
        name: 'OrderCenter',
        activeKey: '/OrderCenter',
        meta: {
          id: 'OrderCenter',
          title: '订单中心',
          icon: 'dingdanzhongxin'
        },
        component: () => import(/* webpackChunkName: "OrderCenter" */ '@/views/OrderCenter'),
        children: [
          {
            path: 'afterSaleSYS',
            name: 'afterSaleSYS',
            activeKey: '/OrderCenter',
            meta: {
              title: '售后管理'
            },
            component: () => import(/* webpackChunkName: "afterSaleSYS" */ '@/views/OrderCenter/afterSaleSYS')
          },
          {
            path: 'afterSaleDetail',
            name: 'afterSaleDetail',
            activeKey: '/OrderCenter',
            meta: {
              title: '售后详情',
              redirectPath: '/OrderCenter/afterSaleSYS',
              customPath: [
                { name: 'OrderCenter', title: '订单中心', icon: 'dingdanzhongxin' },
                { name: 'afterSaleSYS', title: '售后管理', to: 'afterSaleSYS' },
                { name: 'afterSaleDetail', title: '售后详情' }
              ]
            },
            component: () => import(/* webpackChunkName: "afterSaleDetail" */ '@/views/OrderCenter/afterSaleSYS/afterSaleDetail')
          },
          {
            path: 'chatRecord',
            name: 'chatRecord',
            activeKey: '/OrderCenter',
            meta: {
              title: '协商记录',
              redirectPath: '/OrderCenter/afterSaleSYS',
              customPath: [
                { name: 'OrderCenter', title: '订单中心', icon: 'dingdanzhongxin' },
                { name: 'afterSaleSYS', title: '售后管理', to: 'afterSaleSYS' },
                { name: 'chatRecord', title: '协商记录' }
              ]
            },
            component: () => import(/* webpackChunkName: "chatRecord" */ '@/views/OrderCenter/afterSaleSYS/chatRecord')
          },
          {
            path: 'OrderManagement',
            name: 'OrderManagement',
            activeKey: '/OrderCenter',
            meta: {
              title: '订单管理'
            },
            component: () => import(/* webpackChunkName: "OrderManagement" */ '@/views/OrderCenter/OrderManagement')
          },
          {
            path: 'OrderDetails',
            name: 'OrderDetails',
            meta: {
              title: '订单详情',
              redirectPath: '/OrderCenter/OrderManagement',
              customPath: [
                { name: 'OrderCenter', title: '订单中心', icon: 'dingdanzhongxin' },
                { name: 'OrderManagement', title: '订单管理', to: 'OrderManagement' },
                { name: 'OrderDetails', title: '订单详情' }
              ]
            },
            component: () => import(/* webpackChunkName: "OrderDetails" */ '@/views/OrderCenter/OrderManagement/modules/OrderDetails.vue')
          },
          {
            path: 'afterSaleFeedBack',
            name: 'afterSaleFeedBack',
            activeKey: '/OrderCenter',
            meta: {
              title: '售后反馈'
            },
            component: () => import(/* webpackChunkName: "afterSaleFeedBack" */ '@/views/OrderCenter/afterSaleFeedBack')
          },
        ]
      },
      // 商品中心
      {
        path: '/ProductCenter',
        name: 'ProductCenter',
        meta: {
          id: 'ProductCenter',
          title: '商品中心',
          icon: 'shangpinzhongxin'
        },
        component: () => import(/* webpackChunkName: "ProductCenter" */ '@/views/ProductCenter'),
        children: [
          {
            path: 'categorySYS',
            name: 'categorySYS',
            meta: {
              title: '类目管理'
            },
            component: () => import(/* webpackChunkName: "categorySYS" */ '@/views/ProductCenter/CategorySYS')
          },
          {
            path: 'addCategory',
            name: 'addCategory',
            meta: {
              title: '新建类目',
              customPath: [
                { name: 'ProductCenter', title: '商品中心', icon: 'shangpinzhongxin' },
                { name: 'categorySYS', title: '类目管理', to: 'categorySYS' },
                { name: 'addCategory', title: '新建类目' }
              ],
              redirectPath: '/ProductCenter/categorySYS'
            },
            component: () => import(/* webpackChunkName: "categorySYS" */ '@/views/ProductCenter/CategorySYS/addCategory.vue')
          },
          {
            path: 'editCategory',
            name: 'editCategory',
            meta: {
              customPath: [
                { name: 'ProductCenter', title: '商品中心', icon: 'shangpinzhongxin' },
                { name: 'categorySYS', title: '类目管理', to: 'categorySYS' },
                { name: 'editCategory', title: '编辑类目' }
              ],
              title: '编辑类目',
              redirectPath: '/ProductCenter/categorySYS'
            },
            component: () => import(/* webpackChunkName: "categorySYS" */ '@/views/ProductCenter/CategorySYS/addCategory.vue')
          },
          {
            path: 'groupSYS',
            name: 'groupSYS',
            meta: {
              title: '商品分组'
            },
            component: () => import(/* webpackChunkName: "GroupSYS" */ '@/views/ProductCenter/GroupSYS')
          },
          {
            path: 'addGroup',
            name: 'addGroup',
            meta: {
              customPath: [
                {
                  name: 'ProductCenter',
                  title: '商品中心',
                  icon: 'shangpinzhongxin'
                },
                { name: 'groupSYS', title: '商品分组', to: 'groupSYS' },
                { name: 'addGroup', title: '新建分组' }
              ],
              title: '新建分组',
              redirectPath: '/ProductCenter/groupSYS'
            },
            component: () => import(/* webpackChunkName: "GroupSYS" */ '@/views/ProductCenter/GroupSYS/addGroup.vue')
          },
          {
            path: 'editGroup',
            name: 'editGroup',
            meta: {
              title: '编辑分组',
              customPath: [
                {
                  name: 'ProductCenter',
                  title: '商品中心',
                  icon: 'shangpinzhongxin'
                },
                { name: 'groupSYS', title: '商品分组', to: 'groupSYS' },
                { name: 'editGroup', title: '编辑分组' }
              ],
              redirectPath: '/ProductCenter/groupSYS'
            },
            component: () => import(/* webpackChunkName: "GroupSYS" */ '@/views/ProductCenter/GroupSYS/addGroup.vue')
          },
          {
            path: 'BrandSYS',
            name: 'BrandSYS',
            meta: {
              title: '品牌管理'
            },
            component: () => import(/* webpackChunkName: "BrandSYS" */ '@/views/ProductCenter/BrandSYS')
          },
          {
            path: 'addBrand',
            name: 'addBrand',
            meta: {
              customPath: [
                {
                  name: 'ProductCenter',
                  title: '商品中心',
                  icon: 'shangpinzhongxin'
                },
                { name: 'BrandSYS', title: '品牌管理', to: 'BrandSYS' },
                { name: 'addBrand', title: '新建品牌' }
              ],
              title: '新建品牌',
              redirectPath: '/ProductCenter/BrandSYS'
            },
            component: () => import(/* webpackChunkName: "BrandSYS" */ '@/views/ProductCenter/BrandSYS/addBrand.vue')
          },
          {
            path: 'editBrand',
            name: 'editBrand',
            meta: {
              customPath: [
                {
                  name: 'ProductCenter',
                  title: '商品中心',
                  icon: 'shangpinzhongxin'
                },
                { name: 'BrandSYS', title: '品牌管理', to: 'BrandSYS' },
                { name: 'editBrand', title: '编辑品牌' }
              ],
              title: '编辑品牌',
              redirectPath: '/ProductCenter/BrandSYS'
            },
            component: () => import(/* webpackChunkName: "BrandSYS" */ '@/views/ProductCenter/BrandSYS/addBrand.vue')
          },
          {
            path: 'PropertySYS',
            name: 'PropertySYS',
            meta: {
              title: '属性管理'
            },
            component: () => import(/* webpackChunkName: "Property" */ '@/views/ProductCenter/PropertySYS')
          },
          {
            path: 'addProperty',
            name: 'addProperty',
            meta: {
              customPath: [
                {
                  name: 'ProductCenter',
                  title: '商品中心',
                  icon: 'shangpinzhongxin'
                },
                { name: 'PropertySYS', title: '属性管理', to: 'PropertySYS' },
                { name: 'addProperty', title: '新建属性' }
              ],
              title: '新建属性',
              redirectPath: '/ProductCenter/PropertySYS'
            },
            component: () => import(/* webpackChunkName: "BrandSYS" */ '@/views/ProductCenter/PropertySYS/addProperty.vue')
          },
          {
            path: 'editProperty',
            name: 'editProperty',
            meta: {
              customPath: [
                {
                  name: 'ProductCenter',
                  title: '商品中心',
                  icon: 'shangpinzhongxin'
                },
                { name: 'PropertySYS', title: '属性管理', to: 'PropertySYS' },
                { name: 'editProperty', title: '编辑属性' }
              ],
              title: '编辑属性',
              redirectPath: '/ProductCenter/PropertySYS'
            },
            component: () => import(/* webpackChunkName: "BrandSYS" */ '@/views/ProductCenter/PropertySYS/addProperty.vue')
          },
          {
            path: 'viewProperty',
            name: 'viewProperty',
            meta: {
              customPath: [
                {
                  name: 'ProductCenter',
                  title: '商品中心',
                  icon: 'shangpinzhongxin'
                },
                { name: 'PropertySYS', title: '属性管理', to: 'PropertySYS' },
                { name: 'viewProperty', title: '属性详情' }
              ],
              title: '属性详情',
              redirectPath: '/ProductCenter/PropertySYS'
            },
            component: () => import(/* webpackChunkName: "BrandSYS" */ '@/views/ProductCenter/PropertySYS/viewProperty.vue')
          },
          {
            path: 'productSYS',
            name: 'productSYS',
            meta: {
              title: '商品管理'
            },
            component: () => import(/* webpackChunkName: "ProductSYS" */ '@/views/ProductCenter/ProductSYS')
          },
          {
            path: 'editProduct',
            name: 'editProduct',
            meta: {
              title: '编辑商品',
              redirectPath: '/ProductCenter/ProductSYS'
            },
            component: () => import(/* webpackChunkName: "editProduct" */ '@/views/ProductCenter/ProductSYS/modules/commodity.vue')
          },
          {
            path: 'addProduct',
            name: 'addProduct',
            meta: {
              title: '新建商品',
              redirectPath: '/ProductCenter/ProductSYS'
            },
            component: () => import(/* webpackChunkName: "editProduct" */ '@/views/ProductCenter/ProductSYS/modules/commodity.vue')
          }
        ]
      },
      // 用户中心
      {
        path: '/UserCenter',
        name: 'UserCenter',
        meta: {
          id: 'UserCenter',
          icon: 'userCenter',
          title: '用户中心'
        },
        redirect: '/UserCenter/userInfo',
        component: () => import(/* webpackChunkName: "UserCenter" */ '@/views/UserCenter'),
        children: [
          {
            path: 'userSYS',
            name: 'userSYS',
            activeKey: '/UserCenter',
            meta: {
              title: '用户管理'
            },
            component: () => import(/* webpackChunkName: "userSYS" */ '@/views/UserCenter/userSYS/tableList')
          },
          {
            path: 'userInfo',
            name: 'userInfo',
            activeKey: '/UserCenter',
            meta: {
              title: '查看用户',
              redirectPath: '/UserCenter/userSYS'
            },
            component: () => import(/* webpackChunkName: "userSYS" */ '@/views/UserCenter/userSYS/userInfo')
          },
          {
            path: 'tagSYS',
            name: 'tagSYS',
            activeKey: '/UserCenter',
            meta: {
              title: '标签管理'
            },
            component: () => import(/* webpackChunkName: "tagSYS" */ '@/views/UserCenter/tagSYS')
          }
        ]
      },
      // 系统设置
      {
        path: '/SystemSettings',
        name: 'SystemSettings',
        activeKey: '/SystemSettings',
        meta: {
          id: 'SystemSettings',
          title: '系统设置',
          icon: 'xitongshezhi'
        },
        component: () => import(/* webpackChunkName: "SystemSettings" */ '@/views/SystemSettings'),
        children: [
          {
            path: 'PersonnelManagement',
            name: 'PersonnelManagement',
            activeKey: '/SystemSettings',
            meta: {
              title: '人员管理'
            },
            component: () => import(/* webpackChunkName: "PersonnelManagement" */ '@/views/SystemSettings/PersonnelManagement')
          },
          {
            path: 'RoleManagement',
            name: 'RoleManagement',
            activeKey: '/SystemSettings',
            meta: {
              title: '角色管理'
            },
            component: () => import(/* webpackChunkName: "RoleManagement" */ '@/views/SystemSettings/RoleManagement')
          },
          {
            path: 'VersionManagement',
            name: 'VersionManagement',
            activeKey: '/SystemSettings',
            meta: {
              title: '版本管理'
            },
            component: () => import(/* webpackChunkName: "VersionManagement" */ '@/views/SystemSettings/VersionManagement')
          }
        ]
      },
      // 商家中心
      {
        path: '/MerchantCenter',
        name: 'MerchantCenter',
        meta: {
          id: 'MerchantCenter',
          title: '商家中心',
          icon: 'dingdanzhongxin'
        },
        component: () => import(/* webpackChunkName: "MerchantCenter" */ '@/views/MerchantCenter'),
        children: [
          {
            path: 'StoreManagement',
            name: 'StoreManagement',
            activeKey: '/StoreManagement',
            meta: {
              title: '店铺管理'
            },
            component: () => import(/* webpackChunkName: "StoreManagement" */ '@/views/MerchantCenter/StoreManagement')
          },
          {
            path: 'MerchantCA',
            name: 'MerchantCA',
            activeKey: '/MerchantCA',
            meta: {
              title: '店铺认证'
            },
            component: () => import(/* webpackChunkName: "MerchantCA" */ '@/views/MerchantCenter/modules/MerchantCA')
          },
          {
            path: 'InformationReview',
            name: 'InformationReview',
            activeKey: '/InformationReview',
            meta: {
              title: '企业审核'
            },
            component: () => import(/* webpackChunkName: "InformationReview" */ '@/views/MerchantCenter/modules/InformationReview')
          },
          {
            path: 'StoreReview',
            name: 'StoreReview',
            activeKey: '/StoreReview',
            meta: {
              title: '店铺审核'
            },
            component: () => import(/* webpackChunkName: "StoreReview" */ '@/views/MerchantCenter/StoreReview.vue')
          }
        ]
      },
      // 商城管理
      {
        path: '/ShopManagement',
        name: 'ShopManagement',
        activeKey: '/ShopManagement',
        meta: {
          id: 'ShopManagement',
          title: '商城管理',
          icon: 'dingdanzhongxin'
        },
        component: () => import(/* webpackChunkName: "ShopManagement" */ '@/views/ShopManagement'),
        children: [
          {
            path: 'PersonalCenterBannerMgmt',
            name: 'PersonalCenterBannerMgmt',
            activeKey: '/ShopManagement',
            meta: {
              title: '个人中心banner管理',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'PersonalCenterBannerMgmt',
                  title: '个人中心banner管理',
                  to: 'PersonalCenterBannerMgmt'
                }
              ]
            },
            component: () => import(/* webpackChunkName: "PersonalCenterBannerMgmt" */ '@/views/ShopManagement/PersonalCenterBannerMgmt')
          },
          {
            path: 'CreatePersonalCenterBannerMgmt',
            name: 'CreatePersonalCenterBannerMgmt',
            activeKey: '/ShopManagement',
            meta: {
              title: '新建Banner',
              redirectPath: '/ShopManagement/PersonalCenterBannerMgmt',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'PersonalCenterBannerMgmt',
                  title: '个人中心banner管理',
                  to: 'PersonalCenterBannerMgmt'
                },
                { name: 'CreatePersonalCenterBannerMgmt', title: '新建Banner' }
              ]
            },
            component: () => import(/* webpackChunkName: "CreatePersonalCenterBannerMgmt" */ '@/views/ShopManagement/PersonalCenterBannerMgmt/modules/create.vue')
          },
          {
            path: 'UpdatePersonalCenterBannerMgmt',
            name: 'UpdatePersonalCenterBannerMgmt',
            activeKey: '/ShopManagement',
            meta: {
              title: '编辑Banner',
              redirectPath: '/ShopManagement/PersonalCenterBannerMgmt',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'PersonalCenterBannerMgmt',
                  title: '个人中心banner管理',
                  to: 'PersonalCenterBannerMgmt'
                },
                { name: 'UpdatePersonalCenterBannerMgmt', title: '编辑Banner' }
              ]
            },
            component: () => import(/* webpackChunkName: "UpdatePersonalCenterBannerMgmt" */ '@/views/ShopManagement/PersonalCenterBannerMgmt/modules/update')
          },
          {
            path: 'PersonalCenterBannerMgmtDetail',
            name: 'PersonalCenterBannerMgmtDetail',
            activeKey: '/ShopManagement',
            meta: {
              title: 'Banner详情',
              redirectPath: '/ShopManagement/PersonalCenterBannerMgmt',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'PersonalCenterBannerMgmt',
                  title: '个人中心banner管理',
                  to: 'PersonalCenterBannerMgmt'
                },
                { name: 'PersonalCenterBannerMgmtDetail', title: 'Banner详情' }
              ]
            },
            component: () => import(/* webpackChunkName: "PersonalCenterBannerMgmtDetail" */ '@/views/ShopManagement/PersonalCenterBannerMgmt/modules/detail')
          },
          {
            path: 'BannerManagement',
            name: 'BannerManagement',
            activeKey: '/ShopManagement',
            meta: {
              title: 'Banner管理',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'BannerManagement',
                  title: 'Banner管理',
                  to: 'BannerManagement'
                }
              ]
            },
            component: () => import(/* webpackChunkName: "BannerManagement" */ '@/views/ShopManagement/BannerManagement')
          },
          {
            path: 'CreateBannerManagement',
            name: 'CreateBannerManagement',
            activeKey: '/ShopManagement',
            meta: {
              title: '新建Banner',
              redirectPath: '/ShopManagement/BannerManagement',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'BannerManagement',
                  title: 'Banner管理',
                  to: 'BannerManagement'
                },
                { name: 'CreateBannerManagement', title: '新建Banner' }
              ]
            },
            component: () => import(/* webpackChunkName: "CreateBannerManagement" */ '@/views/ShopManagement/BannerManagement/modules/create.vue')
          },
          {
            path: 'UpdateBannerManagement',
            name: 'UpdateBannerManagement',
            activeKey: '/ShopManagement',
            meta: {
              title: '编辑Banner',
              redirectPath: '/ShopManagement/BannerManagement',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'BannerManagement',
                  title: 'Banner管理',
                  to: 'BannerManagement'
                },
                { name: 'UpdateBannerManagement', title: '编辑Banner' }
              ]
            },
            component: () => import(/* webpackChunkName: "UpdateBannerManagement" */ '@/views/ShopManagement/BannerManagement/modules/update')
          },
          {
            path: 'BannerManagementDetail',
            name: 'BannerManagementDetail',
            activeKey: '/ShopManagement',
            meta: {
              title: 'Banner详情',
              redirectPath: '/ShopManagement/BannerManagement',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'BannerManagement',
                  title: 'Banner管理',
                  to: 'BannerManagement'
                },
                { name: 'BannerManagementDetail', title: 'Banner详情' }
              ]
            },
            component: () => import(/* webpackChunkName: "BannerManagementDetail" */ '@/views/ShopManagement/BannerManagement/modules/detail')
          },
          {
            path: 'NavManage',
            name: 'NavManage',
            activeKey: '/ShopManagement',
            meta: {
              title: '导航管理',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'dingdanzhongxin'
                },
                { name: 'NavManage', title: '导航管理', to: 'NavManage' }
              ]
            },
            component: () => import(/* webpackChunkName: "NavManage" */ '@/views/ShopManagement/NavManage')
          },
          {
            path: 'proElManage',
            name: 'proElManage',
            activeKey: '/ShopManagement',
            meta: {
              title: '商品组件',
              customPath: [
                { name: 'ShopManagement', title: '商城管理', icon: 'dingdanzhongxin' },
                { name: 'proElManage', title: '商品组件', to: 'proElManage' }
              ]
            },
            component: () => import(/* webpackChunkName: "proElManage" */ '@/views/ShopManagement/proElManage')
          },
          {
            path: 'addProEl',
            name: 'addProEl',
            activeKey: '/ShopManagement',
            meta: {
              title: '商品组件',
              customPath: [
                { name: 'ShopManagement', title: '商城管理', icon: 'dingdanzhongxin' },
                { name: 'proElManage', title: '商品组件', to: 'proElManage' },
                { name: 'proElManage', title: '新建组件', to: 'addProEl' }
              ],
              redirectPath: '/ShopManagement/proElManage'
            },
            component: () => import(/* webpackChunkName: "addProEl" */ '@/views/ShopManagement/proElManage/addProEl')
          },
          {
            path: 'editProEl',
            name: 'editProEl',
            activeKey: '/ShopManagement',
            meta: {
              title: '商品组件',
              customPath: [
                { name: 'ShopManagement', title: '商城管理', icon: 'dingdanzhongxin' },
                { name: 'proElManage', title: '商品组件', to: 'proElManage' },
                { name: 'proElManage', title: '编辑组件', to: 'editProEl' }
              ],
              redirectPath: '/ShopManagement/proElManage'
            },
            component: () => import(/* webpackChunkName: "addProEl" */ '@/views/ShopManagement/proElManage/addProEl')
          },
          {
            path: 'viewProEl',
            name: 'viewProEl',
            activeKey: '/ShopManagement',
            meta: {
              title: '商品组件',
              customPath: [
                { name: 'ShopManagement', title: '商城管理', icon: 'dingdanzhongxin' },
                { name: 'proElManage', title: '商品组件', to: 'proElManage' },
                { name: 'proElManage', title: '商品组件详情', to: 'viewProEl' }
              ],
              redirectPath: '/ShopManagement/proElManage'
            },
            component: () => import(/* webpackChunkName: "viewProEl" */ '@/views/ShopManagement/proElManage/viewProEl')
          },
          {
            path: 'NewEditNavVersion',
            name: 'NewEditNavVersion',
            activeKey: '/ShopManagement',
            meta: {
              title: '编辑导航版本',
              redirectPath: '/ShopManagement/NavManage',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'dingdanzhongxin'
                },
                { name: 'NavManage', title: '导航管理', to: 'NavManage' },
                { name: 'NewEditNavVersion', title: '编辑导航版本' }
              ]
            },
            component: () => import(/* webpackChunkName: "NewEditNavVersion" */ '@/views/ShopManagement/NavManage/modules/NewEditNavVersion')
          },
          {
            path: 'NavVersion',
            name: 'NavVersion',
            activeKey: '/ShopManagement',
            meta: {
              title: '查看导航版本',
              redirectPath: '/ShopManagement/NavManage',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'dingdanzhongxin'
                },
                { name: 'NavManage', title: '导航管理', to: 'NavManage' },
                { name: 'NavVersion', title: '查看导航版本' }
              ]
            },
            component: () => import(/* webpackChunkName: "NavVersion" */ '@/views/ShopManagement/NavManage/modules/NavVersion')
          },
          {
            path: 'RecommendedProducts',
            name: 'RecommendedProducts',
            activeKey: '/ShopManagement',
            meta: {
              title: '推荐商品管理',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'dingdanzhongxin'
                },
                {
                  name: 'RecommendedProducts',
                  title: '推荐商品管理',
                  to: 'RecommendedProducts'
                }
              ]
            },
            component: () => import(/* webpackChunkName: "RecommendedProducts" */ '@/views/ShopManagement/RecommendedProducts')
          },
          {
            path: 'BatchEditRecSlot',
            name: 'BatchEditRecSlot',
            activeKey: '/ShopManagement',
            meta: {
              title: '编辑推荐位',
              redirectPath: '/ShopManagement/RecommendedProducts',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'dingdanzhongxin'
                },
                {
                  name: 'RecommendedProducts',
                  title: '推荐商品管理',
                  to: 'RecommendedProducts'
                },
                { name: 'BatchEditRecSlot', title: '编辑推荐位' }
              ]
            },
            component: () => import(/* webpackChunkName: "BatchEditRecSlot" */ '@/views/ShopManagement/RecommendedProducts/modules/BatchEditRecSlot')
          },
          {
            path: 'RecSlotDetails',
            name: 'RecSlotDetails',
            activeKey: '/ShopManagement',
            meta: {
              title: '推荐位详情',
              redirectPath: '/ShopManagement/RecommendedProducts',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'dingdanzhongxin'
                },
                {
                  name: 'RecommendedProducts',
                  title: '推荐商品管理',
                  to: 'RecommendedProducts'
                },
                { name: 'RecSlotDetails', title: '推荐位详情' }
              ]
            },
            component: () => import(/* webpackChunkName: "RecSlotDetails" */ '@/views/ShopManagement/RecommendedProducts/modules/RecSlotDetails')
          },
          {
            path: 'SearchManagement',
            name: 'SearchManagement',
            activeKey: '/ShopManagement',
            meta: {
              title: '搜索管理',
              redirectPath: '/ShopManagement/SearchManagement'
            },
            component: () => import(/* webpackChunkName: "SearchManagement" */ '@/views/ShopManagement/SearchManagement')
          },
          {
            path: 'OperationManagement',
            name: 'OperationManagement',
            activeKey: '/ShopManagement',
            meta: {
              title: '运营位管理',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'OperationManagement',
                  title: '运营位管理',
                  to: 'OperationManagement'
                }
              ]
            },
            // redirect: "BannerManagement/BannerManagementList",
            component: () => import(/* webpackChunkName: "OperationManagement" */ '@/views/ShopManagement/OperationManagement')
          },
          {
            path: 'CreateOperationManagement',
            name: 'CreateOperationManagement',
            activeKey: '/ShopManagement',
            meta: {
              title: '新建移动端运营位版本',
              redirectPath: '/ShopManagement/OperationManagement',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'OperationManagement',
                  title: '运营位管理',
                  to: 'OperationManagement'
                },
                { name: 'CreateOperationManagement', title: '新建移动端运营位版本' }
              ]
            },
            component: () => import(/* webpackChunkName: "CreateOperationManagement" */ '@/views/ShopManagement/OperationManagement/modules/createApp')
          },
          {
            path: 'UpdateAppOperationManagement',
            name: 'UpdateAppOperationManagement',
            activeKey: '/ShopManagement',
            meta: {
              title: '编辑移动端运营位版本',
              redirectPath: '/ShopManagement/OperationManagement',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'OperationManagement',
                  title: '运营位管理',
                  to: 'OperationManagement'
                },
                { name: 'UpdateAppOperationManagement', title: '编辑移动端运营位版本' }
              ]
            },
            component: () => import(/* webpackChunkName: "UpdateAppOperationManagement" */ '@/views/ShopManagement/OperationManagement/modules/updateApp')
          },
          {
            path: 'UpdateWebOperationManagement',
            name: 'UpdateWebOperationManagement',
            activeKey: '/ShopManagement',
            meta: {
              title: '编辑web端运营位版本',
              redirectPath: '/ShopManagement/OperationManagement',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'OperationManagement',
                  title: '运营位管理',
                  to: 'OperationManagement'
                },
                { name: 'UpdateWebOperationManagement', title: '编辑web端运营位版本' }
              ]
            },
            component: () => import(/* webpackChunkName: "UpdateWebOperationManagement" */ '@/views/ShopManagement/OperationManagement/modules/createEditWeb.vue')
          },
          {
            path: 'OperationManagementDetail',
            name: 'OperationManagementDetail',
            activeKey: '/ShopManagement',
            meta: {
              title: '编辑运营位版本',
              redirectPath: '/ShopManagement/OperationManagement',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'OperationManagement',
                  title: '运营位管理',
                  to: 'OperationManagement'
                },
                { name: 'OperationManagementDetail', title: '运营位版本' }
              ]
            },
            component: () => import(/* webpackChunkName: "OperationManagementDetail" */ '@/views/ShopManagement/OperationManagement/modules/detail')
          },
          {
            path: 'CreateWebOperationManagement',
            name: 'CreateWebOperationManagement',
            activeKey: '/ShopManagement',
            meta: {
              title: '新建web端运营位版本',
              redirectPath: '/ShopManagement/OperationManagement',
              customPath: [
                {
                  name: 'ShopManagement',
                  title: '商城管理',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'OperationManagement',
                  title: '运营位管理',
                  to: 'OperationManagement'
                },
                { name: 'CreateWebOperationManagement', title: '新建web端运营位版本' }
              ]
            },
            component: () => import(/* webpackChunkName: "CreateWebOperationManagement" */ '@/views/ShopManagement/OperationManagement/modules/createEditWeb.vue')
          },
        ]
      },
      // 评价中心
      {
        path: '/AssessmentCenter',
        name: 'AssessmentCenter',
        activeKey: '/AssessmentCenter',
        meta: {
          id: 'AssessmentCenter',
          title: '评价中心',
          icon: 'dingdanzhongxin'
        },
        component: () => import(/* webpackChunkName: "AssessmentCenter" */ '@/views/AssessmentCenter'),
        children: [
          {
            path: 'FeedBack',
            name: 'FeedBack',
            activeKey: '/AssessmentCenter',
            meta: {
              title: '用户反馈'
            },
            component: () => import(/* webpackChunkName: "FeedBack" */ '@/views/AssessmentCenter/FeedBack')
          },
          {
            path: 'MerchantFeedBack',
            name: 'MerchantFeedBack',
            activeKey: '/AssessmentCenter',
            meta: {
              title: '商家反馈'
            },
            component: () => import(/* webpackChunkName: "MerchantFeedBack" */ '@/views/AssessmentCenter/MerchantFeedback/index')
          }
        ]
      },
      // 消息中心
      {
        path: '/MessageCenter',
        name: 'MessageCenter',
        activeKey: '/MessageCenter',
        meta: {
          title: '客服中心',
          icon: 'dingdanzhongxin',
          id: 'MessageCenter'
        },
        component: () => import(/* webpackChunkName: "MessageCenter" */ '@/views/MessageCenter'),
        children: [
          {
            path: 'MessageManage',
            name: 'MessageManage',
            activeKey: '/MessageCenter',
            meta: {
              title: '消息管理',
              customPath: [
                {
                  name: 'MessageCenter',
                  title: '消息中心',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'MessageManage',
                  title: '消息管理',
                  to: 'MessageManage'
                }
              ]
            },
            component: () => import(/* webpackChunkName: "MessageManage" */ '@/views/MessageCenter/MessageManage')
          },
          {
            path: 'MessageAutoReply',
            name: 'MessageAutoReply',
            activeKey: '/MessageCenter',
            meta: {
              title: '自动回复详情',
            },
            component: () => import(/* webpackChunkName: "MessageAutoReply" */ '@/views/MessageCenter/MessageAutoReply')
          },
          {
            path: 'addEditMessage',
            name: 'addEditMessage',
            activeKey: '/MessageCenter',
            meta: {
              title: '新建消息',
              redirectPath: '/MessageCenter/MessageManage',
              customPath: [
                {
                  name: 'MessageCenter',
                  title: '消息中心',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'MessageManage',
                  title: '消息管理',
                  to: 'MessageManage'
                },
                { name: 'addEditMessage', title: '新建消息' }
              ]
            },
            component: () => import(/* webpackChunkName: "CreateOperationManagement" */ '@/views/MessageCenter/MessageManage/modules/addEditMessage')
          },
          {
            path: 'MessageDetails',
            name: 'MessageDetails',
            activeKey: '/MessageCenter',
            meta: {
              title: '新建消息',
              redirectPath: '/MessageCenter/MessageManage',
              customPath: [
                {
                  name: 'MessageCenter',
                  title: '消息中心',
                  icon: 'shangpinzhongxin'
                },
                {
                  name: 'MessageManage',
                  title: '消息管理',
                  to: 'MessageManage'
                },
                { name: 'MessageDetails', title: '消息详情' }
              ]
            },
            component: () => import(/* webpackChunkName: "CreateOperationManagement" */ '@/views/MessageCenter/MessageManage/modules/MessageDetails')
          }
        ]
      },
      // 营销中心
      {
        path: '/MarketingCenter',
        name: 'MarketingCenter',
        activeKey: '/MarketingCenter',
        meta: {
          id: 'MarketingCenter',
          title: '营销中心',
          icon: 'youhuiguanli'
        },
        component: () => import(/* webpackChunkName: "MarketingCenter" */ '@/views/MarketingCenter'),
        children: [
          {
            path: 'InvitationRewardsManagement',
            name: 'InvitationRewardsManagement',
            activeKey: '/MarketingCenter',
            meta: {
              title: '邀请有礼管理',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'InvitationRewardsManagement',
                  title: '邀请有礼管理',
                  to: 'InvitationRewardsManagement'
                }
              ]
            },
            component: () => import(/* webpackChunkName: "InvitationRewardsManagement" */ '@/views/MarketingCenter/InvitationRewardsManagement')
          },
          {
            path: 'addInviteAndEarnProgram',
            name: 'addInviteAndEarnProgram',
            activeKey: '/MarketingCenter',
            meta: {
              title: '新建活动',
              redirectPath: '/MarketingCenter/InvitationRewardsManagement',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'InvitationRewardsManagement',
                  title: '邀请有礼管理',
                  to: 'InvitationRewardsManagement'
                },
                { name: 'addInviteAndEarnProgram', title: '新建活动' }
              ]
            },
            component: () => import(/* webpackChunkName: "addInviteAndEarnProgram" */ '@/views/MarketingCenter/InvitationRewardsManagement/modules/addInviteAndEarnProgram')
          },
          {
            path: 'InviteRewardsDetails',
            name: 'InviteRewardsDetails',
            activeKey: '/MarketingCenter',
            meta: {
              title: '活动详情',
              redirectPath: '/MarketingCenter/InvitationRewardsManagement',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'InvitationRewardsManagement',
                  title: '邀请有礼管理',
                  to: 'InvitationRewardsManagement'
                },
                { name: 'InviteRewardsDetails', title: '活动详情' }
              ]
            },
            component: () => import(/* webpackChunkName: "InviteRewardsDetails" */ '@/views/MarketingCenter/InvitationRewardsManagement/modules/InviteRewardsDetails')
          },
          {
            path: 'CouponManagement',
            name: 'CouponManagement',
            activeKey: '/MarketingCenter',
            meta: {
              title: '优惠券管理',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'CouponManagement',
                  title: '优惠券管理',
                  to: 'CouponManagement'
                }
              ]
            },
            component: () => import(/* webpackChunkName: "CouponManagement" */ '@/views/MarketingCenter/CouponManagement')
          },
          {
            path: 'ClaimRecord',
            name: 'ClaimRecord',
            activeKey: '/MarketingCenter',
            meta: {
              title: '领取记录',
              redirectPath: '/MarketingCenter/CouponManagement',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'CouponManagement',
                  title: '优惠券管理',
                  to: 'CouponManagement'
                },
                { name: 'ClaimRecord', title: '领取记录' }
              ]
            },
            component: () => import(/* webpackChunkName: "ClaimRecord" */ '@/views/MarketingCenter/CouponManagement/modules/ClaimRecord')
          },
          {
            path: 'addCoupon',
            name: 'addCoupon',
            activeKey: '/MarketingCenter',
            meta: {
              title: '新建优惠劵',
              redirectPath: '/MarketingCenter/CouponManagement',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'CouponManagement',
                  title: '优惠券管理',
                  to: 'CouponManagement'
                },
                { name: 'addCoupon', title: '新建优惠劵' }
              ]
            },
            component: () => import(/* webpackChunkName: "addCoupon" */ '@/views/MarketingCenter/CouponManagement/modules/addCoupon')
          },
          {
            path: 'editCoupon',
            name: 'editCoupon',
            activeKey: '/MarketingCenter',
            meta: {
              title: '编辑优惠劵',
              redirectPath: '/MarketingCenter/CouponManagement',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'CouponManagement',
                  title: '优惠券管理',
                  to: 'CouponManagement'
                },
                { name: 'editCoupon', title: '编辑优惠劵' }
              ]
            },
            component: () => import(/* webpackChunkName: "editCoupon" */ '@/views/MarketingCenter/CouponManagement/modules/addCoupon')
          },
          {
            path: 'couponDetails',
            name: 'couponDetails',
            activeKey: '/MarketingCenter',
            meta: {
              title: '优惠劵详情',
              redirectPath: '/MarketingCenter/CouponManagement',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'CouponManagement',
                  title: '优惠券管理',
                  to: 'CouponManagement'
                },
                { name: 'couponDetails', title: '优惠劵详情' }
              ]
            },
            component: () => import(/* webpackChunkName: "couponDetails" */ '@/views/MarketingCenter/CouponManagement/modules/couponDetails')
          },
          {
            path: 'SeckillManagement',
            name: 'SeckillManagement',
            activeKey: '/MarketingCenter',
            meta: {
              title: '秒杀管理',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'SeckillManagement',
                  title: '秒杀管理',
                  to: 'SeckillManagement'
                }
              ]
            },
            component: () => import(/* webpackChunkName: "SeckillManagement" */ '@/views/MarketingCenter/SeckillManagement')
          },
          {
            path: 'addEditSeckillActivity',
            name: 'addEditSeckillActivity',
            activeKey: '/MarketingCenter',
            meta: {
              title: '新建秒杀活动',
              redirectPath: '/MarketingCenter/SeckillManagement'
            },
            component: () => import(/* webpackChunkName: "addEditSeckillActivity" */ '@/views/MarketingCenter/SeckillManagement/modules/addEditSeckillActivity')
          },
          {
            path: 'SeckillDetails',
            name: 'SeckillDetails',
            activeKey: '/MarketingCenter',
            meta: {
              title: '秒杀活动详情',
              redirectPath: '/MarketingCenter/SeckillManagement',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'SeckillManagement',
                  title: '秒杀管理',
                  to: 'SeckillManagement'
                },
                { name: 'SeckillDetails', title: '秒杀活动详情' }
              ]
            },
            component: () => import(/* webpackChunkName: "SeckillDetails" */ '@/views/MarketingCenter/SeckillManagement/modules/SeckillDetails')
          },
          {
            path: 'BargainingManagement',
            name: 'BargainingManagement',
            activeKey: '/MarketingCenter',
            meta: {
              title: '砍价管理',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'BargainingManagement',
                  title: '砍价管理',
                  to: 'BargainingManagement'
                }
              ]
            },
            component: () => import(/* webpackChunkName: "BargainingManagement" */ '@/views/MarketingCenter/BargainingManagement')
          },
          {
            path: 'BargainingActivInitiatorCount',
            name: 'BargainingActivInitiatorCount',
            activeKey: '/MarketingCenter',
            meta: {
              title: '领取记录',
              redirectPath: '/MarketingCenter/BargainingManagement',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'BargainingManagement',
                  title: '砍价管理',
                  to: 'BargainingManagement'
                },
                { name: 'BargainingActivInitiatorCount', title: '领取记录' }
              ]
            },
            component: () => import(/* webpackChunkName: "BargainingActivInitiatorCount" */ '@/views/MarketingCenter/BargainingManagement/modules/BargainingActivInitiatorCount')
          },
          {
            path: 'addBargainingActiv',
            name: 'addBargainingActiv',
            activeKey: '/MarketingCenter',
            meta: {
              title: '新建砍价活动',
              redirectPath: '/MarketingCenter/BargainingManagement',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'BargainingManagement',
                  title: '砍价管理',
                  to: 'BargainingManagement'
                },
                { name: 'addBargainingActiv', title: '新建砍价活动' }
              ]
            },
            component: () => import(/* webpackChunkName: "addBargainingActiv" */ '@/views/MarketingCenter/BargainingManagement/modules/addBargainingActiv')
          },
          {
            path: 'editBargainingActiv',
            name: 'editBargainingActiv',
            activeKey: '/MarketingCenter',
            meta: {
              title: '编辑砍价活动',
              redirectPath: '/MarketingCenter/BargainingManagement',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'BargainingManagement',
                  title: '砍价管理',
                  to: 'BargainingManagement'
                },
                { name: 'editBargainingActiv', title: '编辑砍价活动' }
              ]
            },
            component: () => import(/* webpackChunkName: "editBargainingActiv" */ '@/views/MarketingCenter/BargainingManagement/modules/addBargainingActiv')
          },
          {
            path: 'ActivDetails',
            name: 'ActivDetails',
            activeKey: '/MarketingCenter',
            meta: {
              title: '优惠劵详情',
              redirectPath: '/MarketingCenter/BargainingManagement',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'BargainingManagement',
                  title: '砍价管理',
                  to: 'BargainingManagement'
                },
                { name: 'ActivDetails', title: '砍价详情' }
              ]
            },
            component: () => import(/* webpackChunkName: "ActivDetails" */ '@/views/MarketingCenter/BargainingManagement/modules/ActivDetails')
          },
          {
            path: 'DataStatistics',
            name: 'DataStatistics',
            activeKey: '/MarketingCenter',
            meta: {
              title: '数据统计',
              redirectPath: '/MarketingCenter/InvitationRewardsManagement',
              customPath: [
                {
                  name: 'MarketingCenter',
                  title: '营销中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'InvitationRewardsManagement',
                  title: '邀请有礼管理',
                  to: 'InvitationRewardsManagement'
                },
                {
                  name: 'DataStatistics',
                  title: '数据统计',
                }
              ]
            },
            component: () => import(/* webpackChunkName: "DataStatistics" */ '@/views/MarketingCenter/InvitationRewardsManagement/modules/DataStatistics')
          },
        ],
      },
      {
        path: '/FinancialCenter',
        name: 'FinancialCenter',
        activeKey: '/FinancialCenter',
        meta: {
          id: 'FinancialCenter',
          title: '财务中心',
          icon: 'youhuiguanli'
        },
        component: () => import(/* webpackChunkName: "FinancialCenter" */ '@/views/FinancialCenter'),
        children: [
          {
            path: 'MerchantAccount',
            name: 'MerchantAccount',
            activeKey: '/FinancialCenter',
            meta: {
              title: '商家账户',
              customPath: [
                {
                  name: 'FinancialCenter',
                  title: '财务中心',
                  icon: 'youhuiguanli'
                },
                {
                  name: 'MerchantAccount',
                  title: '商家账户',
                  to: 'MerchantAccount'
                }
              ]
            },
            component: () => import(/* webpackChunkName: "MerchantAccount" */ '@/views/FinancialCenter/MerchantAccount')
          },
          {
            path: 'PlatformTransactionDetails',
            name: 'PlatformTransactionDetails',
            activeKey: '/PlatformTransactionDetails',
            meta: { title: '平台收支明细' },
            component: () => import(/* webpackChunkName: "PlatformTransactionDetails" */ '@/views/FinancialCenter/PlatformTransactionDetails')
          },
          {
            path: 'WithdrawalList',
            name: 'WithdrawalList',
            activeKey: '/withdrawalList',
            meta: {
              id: 'withdrawalList',
              title: '提现申请'
            },
            component: () => import(/* webpackChunkName: "withdrawalList" */ '@/views/FinancialCenter/WithdrawalList')
          },
          {
            path: 'MerchantFinancialDetails',
            name: 'MerchantFinancialDetails',
            activeKey: '/MerchantFinancialDetails',
            meta: { title: '商家收支明细' },
            component: () => import(/* webpackChunkName: "MerchantFinancialDetails" */ '@/views/FinancialCenter/MerchantFinancialDetails')
          },
          {
            path: 'BankAccountReview',
            name: 'BankAccountReview',
            activeKey: '/BankAccountReview',
            meta: { title: '商家收支明细' },
            component: () => import(/* webpackChunkName: "BankAccountReview" */ '@/views/FinancialCenter/BankAccountReview')
          },
          {
            path: 'FreightMonthlyOrder',
            name: 'FreightMonthlyOrder',
            activeKey: '/FreightMonthlyOrder',
            meta: { title: '运费月结单' },
            component: () => import(/* webpackChunkName: "FreightMonthlyOrder" */ '@/views/FinancialCenter/FreightMonthlyOrder')
          },
        
        ]
      },
    ]
  },
  // 容器视图
  {
    path: '/loginView',
    name: 'LoginView',
    redirect: '/login',
    component: () => import(/* webpackChunkName: "LoginView" */ '@/views/Login/loginView.vue'),
    children: [
      // 登录
      {
        path: '/login',
        name: 'loginIndex',
        activeKey: '/login',
        meta: {
          id: 'loginIndex',
          title: '登录'
        },
        component: () => import(/* webpackChunkName: "loginIndex" */ '@/views/Login/modules/index.vue')
      },
      // 忘记密码
      {
        path: '/forgetPwd',
        name: 'forgetPwd',
        activeKey: '/login',
        meta: {
          id: 'forgetPwd',
          title: '忘记密码'
        },
        component: () => import(/* webpackChunkName: "forgetPwd" */ '@/views/Login/modules/forgetPwd.vue')
      },
      // 注册
      {
        path: '/register',
        name: 'register',
        activeKey: '/login',
        meta: {
          id: 'register',
          title: '注册'
        },
        component: () => import(/* webpackChunkName: "forgetPwd" */ '@/views/Login/modules/register')
      },
      
    ]
  }
]

//避免冗余导航到当前位置
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 使用全局导航守卫函数
router.beforeEach(globalGuard)

export default router
